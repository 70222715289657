<template>
  <span>
    <el-row
      v-if="!$route.params.id"
      class="reports-body d-flex flex-direction-col h-100"
    >
      <div class="white-back-color h-100">
        <v-col>
          <table
            class="custom-table-wrapper mb-4"
            v-for="(item, index) in reportsTableRoutesList"
            :key="index"
          >
            <tbody>
              <tr>
                <td class="mb-2">
                  <div class="h4">{{ index }}</div>
                </td>
              </tr>
              <tr
                class="ct__tbody__tr"
                v-for="(subItem, subItemIndex) in item"
                :key="subItemIndex"
              >
                <td class="ct__td w-100px">
                  {{ subItem.form_number }}
                </td>
                <!-- <td class="ct__td w-100px">{{ subItem.form_number }}</td> -->
                <td class="ct__td flex-1 text-left">
                  <router-link
                    :to="{
                      path: `/${subItem.routeName}/${subItem.form_number}`
                    }"
                    class="text-dark text-hover-primary"
                  >
                    {{ subItem.title }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </div>
    </el-row>
    <template v-else>
      <router-view :key="$route.params.id" />
    </template>
  </span>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      reportsTableRoutesList: {
        'Shartnomalar va mijozlar': [
          {
            title: "Shartnoma va mijozlar ro'yxati",
            path: '',
            routeName: 'reports',
            form_number: 1
          },
          // {
          //   title: 'Akt Sverka',
          //   path: '',
          //   routeName: 'reports',
          //   form_number: 6
          // },
          {
            title: 'Debet-Kredit qarzdorliklar',
            path: '',
            routeName: 'reports',
            form_number: 29
          }
        ],

        Moliya: [
          {
            title: 'KorpKarta hisoboti',
            path: '',
            routeName: 'reports',
            form_number: 21
          },
           {
            title: 'Korporativ Kartalar hisoboti',
            path: '',
            routeName: 'reports',
            form_number: 33
          },
          {
            title: 'Perechisleniya xarajatlari',
            path: '',
            routeName: 'reports',
            form_number: 26
          },

          {
            title: 'Naqd xarajatlar',
            path: '',
            routeName: 'reports',
            form_number: 27
          },

          {
            title: 'Soliq va foiz xarajatlari',
            path: '',
            routeName: 'reports',
            form_number: 28
          },

          {
            title: 'Moliyaviy hisobot',
            path: '',
            routeName: 'reports',
            form_number: 30
          },

          // {
          //   title: 'Yillar Hisobi',
          //   path: '',
          //   routeName: 'reports',
          //   form_number: 31
          // },

          {
            title:'Moliyaviy natijalar',
            path: '',
            routeName: 'account_results',
            form_number: ''
          },

          {
            title:'Balans',
            path: '',
            routeName: 'newbalancev1',
            form_number: ''
          }
        ],

        Logistika: [
          {
            title: 'Birlik xarajatlar',
            path: '',
            routeName: 'reports',
            form_number: 5
          },

          {
            title: "Reyslar ro'yxati",
            path: '',
            routeName: 'reports',
            form_number: 7
          }
        ],

        Garaj: [
          {
            title: "Avtomashinalar ro'yxati",
            path: '',
            routeName: 'reports',
            form_number: 3
          },

          {
            title: "AvtoTex ma'lumot",
            path: '',
            routeName: 'reports',
            form_number: 4
          },

          {
            title: 'Odometr',
            path: '',
            routeName: 'reports',
            form_number: 20
          },

          {
            title: 'Remont xarajatlari',
            path: '',
            routeName: 'reports',
            form_number: 23
          },

          {
            title: "Yonilg'i xarajati",
            path: '',
            routeName: 'reports',
            form_number: 22
          }
        ],

        HR: [
          {
            title: 'Xaydovchilar premiyasi',
            path: '',
            routeName: 'reports',
            form_number: 18
          },
          {
            title: 'Premiyaga buyruq',
            path: '',
            routeName: 'reports',
            form_number: 19
          },

          {
            title: "Xodimlarga to'lovlar",
            path: '',
            routeName: 'reports',
            form_number: 25
          }
        ]
      }
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Hisobotlar' }])
  },

  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, [])
  }
}
</script>
